import { downloadXlsxFile } from "@/features/custom_downloads";
import { PortfolioExportAssetLevelDownloadButton } from "@/features/pe_aldd";
import { RemoteJobActionsProps } from "@/features/remote_jobs";
import { logToolUsage } from "@/features/tracking";
import { useGetGroupsQuery, useIsOwnerQuery } from "@/store/services/supabase";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { exportPortfolioSummary } from "@/tools/aggregate/portfolio-export/utils/stream";
import { useKeys } from "@/utils/hooks/useKeys";
import { CloudDownloadOutlined, EllipsisOutlined, StopOutlined } from "@ant-design/icons";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import { useUser } from "@supabase/auth-helpers-react";
import { Button, ButtonProps, Dropdown, Input, Modal, notification, Select, Switch } from "antd";
import { MenuProps } from "antd/lib";
import React, { useState } from "react";

/**
 *
 */
interface ActionButtonProps extends ButtonProps {
  isRaw?: boolean;
}
export type PortfolioExportJobActionsProps = Omit<RemoteJobActionsProps<PortfolioExportJobSchema>, "status" | "completedActions">;
type OnEditProps = {
  schema: PortfolioExportJobSchema;
  name: string;
  isPrivate: boolean;
  groupId: string;
};
export const PortfolioExportJobActions = (props: PortfolioExportJobActionsProps) => {
  const [isExporting, setIsExporting] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const {
    data: isOwner
  } = useIsOwnerQuery();
  const [modal, modalContext] = Modal.useModal();
  const [notify, notificationContext] = notification.useNotification();
  const [key] = useKeys();
  const buttons: (ActionButtonProps | undefined)[] = [];
  const exportSummary = async () => {
    setIsExporting(true);
    if (!key.user) return;
    try {
      const buffer = await exportPortfolioSummary(props.schema.id, props.schema.summaryId, props.schema.secret, props.schema.stamps);
      if (buffer) {
        downloadXlsxFile(buffer, {
          filename: props.schema.name + "_summary.xlsx"
        });

        // Log to HubSpot when this has completed.
        logToolUsage({
          id: "aggregate.portfolioExport.summary",
          count: props.schema.assets.totalCount,
          portfolioId: props.schema.id,
          type: "portfolio_export:summary"
        });
      }
    } catch (err: any) {
      console.error("Error while exporting Portfolio Summary: ", err);
      notify.error({
        duration: 0,
        message: "Something went wrong",
        description: Array.isArray(err.errors) ? err.errors.join(" ") : "Please try again or contact your administrator"
      });
    }
    setIsExporting(false);
  };
  const onDeleteJob = async (name: string, action: "delete" | "cancel" = "delete") => {
    const content = action === "delete" ? "Deleting this will permanently remove it for all members of your organisational group. Are you sure you want to continue?" : `Are you sure you want to cancel ${name}?`;
    const confirmed = await modal.confirm({
      content,
      okText: "Ok"
    });
    if (confirmed) {
      props.onDeleteJob();
    }
  };
  switch (props.schema.status) {
    case "completed_with_errors":
    case "completed":
      buttons.push({
        loading: isExporting,
        disabled: isExporting,
        icon: <CloudDownloadOutlined />,
        children: "Summary",
        onClick: exportSummary,
        className: "!font-normal"
      }, {
        children: <PortfolioExportAssetLevelDownloadButton schema={props.schema} />,
        isRaw: true
      });
      break;
    case "error":
      buttons.push({
        icon: <DeleteOutlined />,
        children: "Delete",
        onClick: async () => await onDeleteJob(props.schema.name)
      });
      break;
    default:
      buttons.push({
        icon: <StopOutlined />,
        children: "Cancel",
        onClick: async () => await onDeleteJob(props.schema.name, "cancel")
      });
  }
  const dropdownMenuItems: MenuProps["items"] = [{
    key: "edit-portfolio",
    label: "Edit...",
    onClick: async () => setShowEditModal(true)
  }, {
    key: "delete-portfolio",
    label: "Delete",
    onClick: async () => await onDeleteJob(props.schema.name),
    icon: <DeleteOutlined />
  }];
  const handleEditOk = async (val: OnEditProps) => {
    if (props.onEditJob) {
      await props.onEditJob(val);
    }
  };
  return <div style={{
    display: "flex",
    gap: "0.25rem",
    alignItems: "center",
    fontWeight: 500
  }} data-sentry-component="PortfolioExportJobActions" data-sentry-source-file="PortfolioExportJobActions.tsx">
      {notificationContext}
      {modalContext}

      {buttons.map((button, index) => {
      if (!button) {
        return undefined;
      }
      if (button.isRaw) {
        return <React.Fragment key={index}>{button.children}</React.Fragment>;
      }
      return <Button key={index} size="small" style={{
        fontWeight: "inherit"
      }} {...button} />;
    })}

      <Dropdown trigger={["click"]} disabled={!["completed", "completed_with_errors"].includes(props.schema.status)} menu={{
      items: dropdownMenuItems
    }} overlayStyle={{
      width: 160
    }} data-sentry-element="Dropdown" data-sentry-source-file="PortfolioExportJobActions.tsx">
        <Button size="small" icon={<EllipsisOutlined rotate={90} />} style={{
        fontWeight: "inherit"
      }} type="text" data-sentry-element="Button" data-sentry-source-file="PortfolioExportJobActions.tsx" />
      </Dropdown>

      {props.onEditJob && <EditJob open={showEditModal} isOwner={isOwner || false} schema={props.schema} onCancel={() => setShowEditModal(false)} onOk={handleEditOk} hasOrgKeys={!!key.orgSecret} />}
    </div>;
};
const EditJob = ({
  schema,
  isOwner,
  open,
  onCancel,
  onOk,
  hasOrgKeys
}: {
  schema: PortfolioExportJobSchema;
  isOwner: boolean;
  open: boolean;
  onCancel: () => void;
  onOk: (props: OnEditProps) => Promise<void>;
  hasOrgKeys: boolean;
}) => {
  const {
    data: groups
  } = useGetGroupsQuery();
  const user = useUser();
  const [name, setName] = useState(schema.name);
  const [isPrivate, setIsPrivate] = useState(schema.isPrivate || false);
  const [groupId, setGroupId] = useState(schema.groupId);
  if (!groups) return <></>;
  const groupOpts = groups.map(g => ({
    key: g.id,
    value: g.id,
    label: g.name || ""
  }));
  const handleOk = async () => {
    console.log("ok");
    await onOk({
      schema,
      groupId,
      name,
      isPrivate
    }).then(onCancel);
  };
  return <Modal title={`Edit ${schema.name}`} open={open} onCancel={onCancel} onOk={handleOk} okText="Save" data-sentry-element="Modal" data-sentry-component="EditJob" data-sentry-source-file="PortfolioExportJobActions.tsx">
      <div className="grid grid-cols-4 items-center gap-y-2 py-4">
        <div>Name</div>
        <Input value={name} className="col-span-3" onChange={val => setName(val.target.value)} data-sentry-element="Input" data-sentry-source-file="PortfolioExportJobActions.tsx" />

        {user?.id === schema.userId && <>
            <div>Private</div>
            <div className="col-span-3">
              <Switch value={isPrivate} onChange={() => setIsPrivate(!isPrivate)} disabled={!hasOrgKeys} />
            </div>
            <div className="col-span-3 col-start-2 h-4 text-xs text-gray-500">
              {isPrivate ? "Your portfolio will be only visible to you." : "Your portfolio will be visible to everyone in the group."}
            </div>
          </>}

        {!isPrivate && <>
            <div>Share with</div>
            <Select placeholder="Select group..." options={groupOpts} className="col-span-3" onChange={e => setGroupId(e)} value={groupId} disabled={!isOwner} />
          </>}
      </div>
    </Modal>;
};