import type { Tools } from "../../types/tools";

const tools: Tools = {
  meta: {
    title: "Welcome To The Hub",
    subtitle: "Please select a tool from the sidebar.",
    zeroToolsAccess: {
      subtitle: "You do not have access to tools at the moment. Please contact your administrator to get started.",
    },
    accessDenied: {
      title: "You don't have permission to access this tool",
      blurb:
        "If you have recently been granted access, please log out and then log back in. For access requests, kindly contact your administrator.",
    },
  },
  settings: {
    title: "Settings",
    archetype: {
      title: "Asset Type (Archetype)",
    },
    buildYear: {
      title: "Build Year",
    },
    heightAboveGround: {
      title: "Height Above Ground",
    },
  },
  admin: {
    title: "Administration",

    hubspotDraftTester: {
      title: "HubSpot Draft Tester",
      subtitle: "Try out HubSpot pages in the Hub before publishing.",
    },
    portfolioAnalysisGrabber: {
      title: "Portfolio Analysis Grabber",
      subtitle: "Retrieve files associated with a Common Portfolio Analysis run.",
    },
  },
  aggregate: {
    title: "Comprehensive Analysis Tools",

    blaze: {
      title: "Multi-Asset",
      subtitle: "Analyse and process large sets of assets.",
      steps: ["Provide File", "Customise", "Process Data", "View Results"],
    },
    portfolioExport: {
      title: "Portfolio Insights",
      subtitle:
        "The Portfolio Insights tool delivers a comprehensive and detailed climate risk assessment for portfolios of up to 20,000 single point assets.",
      description: {
        title: "What is Portfolio Insights?",
        blurb:
          "The Portfolio Insights tool delivers a comprehensive and detailed climate risk assessment for portfolios of up to 20,000 single point assets. This tool provides advanced asset configurability and comprehensive outputs at both an individual asset and aggregated portfolio level so you can assess a portfolio's forward-looking climate risk under a range of  settings and emission scenarios. Unlike the Screening Tools, you can select the appropriate settings for each asset within your portfolio; and adjust the Height Above Ground, Build Year, Market Value, Replacement Cost, and Mortgage Rate. Detailed risk metrics are provided at 5-yearly time intervals and under all common emission scenarios. This tool is ideal for meeting the risk management, investment planning, and regulatory reporting needs of financial institutions, asset managers, and corporate sustainability teams with large-scale asset holdings.",
      },
      processingAlert: {
        title: "Please do not close your browser while your portfolio is processing.",
        blurb:
          "This typically takes a few minutes to a few hours, depending on your portfolio size. If processing exceeds 2 days, please reach out to the service desk.",
      },
    },
    commonPortfolioAnalysis: {
      title: "Portfolio Analysis",
      subtitle: "Process a .csv file containing up to 2000 assets.",
    },
    remoteJobs: {
      title: "Remote Jobs",
      subtitle: "Process data on remote servers.",
      job: "Job",
      startNewRun: "Start New Run",
    },
  },
  assetLevelDeepDive: {
    title: "Asset-LeveL Deep Dive",
  },
  create: {
    lssFromMap: {
      title: "Create Large Site From Map",
      subtitle: "Draw the bounds of your Large Site and place points within it.",
    },
  },
  marketIntelligence: {
    title: "Market Intelligence Tools",

    gdcr: {
      title: "Gross Domestic Climate Risk",
      subtitle: "",
    },
    mci: {
      title: "Company Database",
      subtitle: "",
    },
  },
  coordinateGeneration: {
    title: "Coordinate Generation",

    largeSiteGrid: {
      title: "Large Site Grid (Preview)",
      subtitle: "Generate coordinates from a polygon of a large site.",
    },
    shapeGridMapper: {
      title: "Shape Grid Mapper",
      subtitle: "[IN DEVELOPMENT] Generate equally spaced points from polygons and lines.",
    },
  },
  reference: {
    title: "Resources",

    database: {
      title: "Database",
      subtitle: "Browse or search our library to explore external datasets.",
    },
    glossary: {
      title: "$t(pages:knowledgeBase.glossary.title)",
      subtitle: "View definitions for terms used across the platform.",
    },
    methods: {
      title: "$t(pages:knowledgeBase.method.title)",
      subtitle: "View methodology documents and frequently asked questions.",
    },
    modelling: {
      title: "$t(pages:knowledgeBase.modelling.title)",
      subtitle: "View Climate ",
    },
    riskMap: {
      title: "Risk Map (Draft)",
      subtitle: "See climate change risk by year.",
    },
    faqs: {
      title: "$t(pages:knowledgeBase.faqs.title)",
      subtitle: "View frequently asked questions",
    },
    changeLog: {
      title: "$t(pages:knowledgeBase.changeLog.title)",
      subtitle: "View changelogs",
    },
  },
  riskBands: {
    title: "Screening Tools",

    batch: {
      title: "Quick Batch Screen",
      subtitle:
        "This tool provides a rapid assessment of climate risk for up to 30 points across six key time periods under four major emission scenarios.",
      description: {
        title: "What is Quick Batch Screen?",
        blurb:
          "The Quick Batch Screen tool provides a rapid assessment of climate risk for up to 30 points across six key time periods under four major emission scenarios. This tool provides [ABC Risk Bands](/glossary/risk-bands) and [Significant Hazard](/glossary/significant-hazards) for each point on a visual map and is ideal for high-level screening and preliminary risk assessments of a limited list of locations. For the most accurate results, please enter latitude/longitude co-ordinates.\nPlease note: Quick Batch Screen applies the same asset settings to all addresses in the batch.",
      },
      steps: ["Add Data", "Process data", "Results"],
      step2Steps: ["Geocode all addresses", "Obtain risk bands for all addresses"],
    },
    single: {
      title: "Single Asset Screen",
      subtitle:
        "A flexible tool for conducting a quick and simple assessment of a single existing address or site of a potential new development. ",
      description: {
        title: "What is Single Asset Screen?",
        blurb:
          "The Single Asset Screen is a flexible tool for conducting a quick and simple assessment of a single existing address or site of a potential new development. Simply provide an address using the search capability or click anywhere on the map and select from a range of building archetypes using default settings. The Single Asset Screen will return high level climate risk results, including the asset's [risk bands](/glossary/risk-bands) over six key time intervals and [Significant Hazard](/glossary/significant-hazards)  relevant to that location.",
      },
    },
    largeSite: {
      title: "Large Site Screening",
      subtitle: "Analyse and process a single large asset.",
    },
  },
};

export default tools;
