import { getCurrencyCode, getDefaultInputValues, getRiskBandsMode } from "@/features/branding";
import { decryptData, getSecretKey } from "@/features/cryptography";
import { store } from "@/store";
import { facadesUrl } from "@/store/services/facades";
import { supabaseApi } from "@/store/services/supabase";
import { dispatchWithRetries } from "@/store/services/supabase/utils";
import { PortfolioExportResultError, PortfolioExportSummaryRequest } from "@/tools/aggregate/portfolio-export/types";
import { KeyPairAndSalt, SecretSalt } from "@/types/cryptography";
import { hexToArrayBuffer } from "@/utils/crypto";
import { getUTCDate } from "@/utils/datetime";
import { unparse } from "@climaterisk/papaparse";
import { RcFile } from "antd/es/upload";

/**
 *
 * @param file -
 * @returns
 */
export const buildUploadStream = (file: RcFile): ReadableStream => {
  const reader = file.stream().getReader();

  return new ReadableStream({
    pull: async (controller) => {
      const { done, value } = await reader.read();

      if (done) {
        controller.close();
        return;
      }

      controller.enqueue(value);
    },
  });
};

export const streamResults = async (ids: string[], stamps: string[]): Promise<any> => {
  const url = new URL(`${facadesUrl}${getModeEndpoint()}/results`);
  if (stamps.length) {
    stamps.forEach((stamp) => url.searchParams.append("stamps", stamp));
  }

  // Send all IDs to Facades batch endpoint.
  // TODO: Move at least some of this code to the Facades API wrapper.
  return fetch(url.toString(), {
    method: "POST",
    headers: {
      Authorization: `Basic ${process.env.NEXT_PUBLIC_FACADES_ABCDE_API_KEY}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(ids),
  }).then((data) => {
    return {
      type: "success",
      stream: data.body,
    };
  });
};

const downloadPortfolioSummary = ({ summaryId }: { summaryId: string }) => {
  return fetch(`${facadesUrl}portfolio/download`, {
    method: "POST",
    headers: {
      Authorization: `Basic ${process.env.NEXT_PUBLIC_FACADES_ABCDE_API_KEY}`,
      Accept: "application/vnd.oasis.opendocument.spreadsheet",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ report_id: summaryId }),
  });
};

export const getPortfolioUsageStream = (orgKey: KeyPairAndSalt, userKey: KeyPairAndSalt): ReadableStream => {
  let count = 0;
  const batchSize = 100;
  const timePeriod = { from: getUTCDate(2022, 1, 1), until: getUTCDate(new Date().getFullYear() + 1, 0, 1) };

  const encoder = new TextEncoder();

  return new ReadableStream({
    start: (controller) => {
      count = 0;

      controller.enqueue(
        encoder.encode(
          [
            "Portfolio Run Date",
            "User",
            "Group",
            "Portfolio ID",
            "Portfolio Name",
            "Requested",
            "Errored",
            "Analysed",
            "Date Deleted",
          ].join(",") + "\n"
        )
      );
    },
    pull: async (controller) => {
      const action = supabaseApi.endpoints.getAllPortfolios.initiate({
        dateFrom: timePeriod.from,
        dateUntil: timePeriod.until,
        start: count * batchSize,
        length: batchSize,
      });

      const data = await dispatchWithRetries<any[]>({ action, maxRetries: 3, timeout: 5000 });
      count++;

      if (data === undefined || !data.length) {
        controller.close();
        return;
      }
      // decrypt portfolio results
      const portfolios = await Promise.all(
        data.map(async (item) => {
          try {
            if (!item.secret_enc) throw Error;

            const secret = item.is_private
              ? await getSecretKey(userKey.keyPair.privateKey, item.secret_enc)
              : await getSecretKey(orgKey.keyPair.privateKey, item.secret_enc);
            const name = await decryptData(secret, hexToArrayBuffer(item.name || ""));

            return {
              ...item,
              key: item.id,
              name,
            };
          } catch {
            return {
              ...item,
              key: item.id,
              name: "[Encrypted Name]",
            };
          }
        })
      );

      let rows = "";
      portfolios.map((portfolio) => {
        const row = [
          portfolio.created_at,
          portfolio.email,
          portfolio.group_name,
          portfolio.id,
          portfolio.name,
          portfolio.total ?? 0,
          portfolio.error ?? 0,
          portfolio.success ?? 0,
          portfolio.deleted_at,
        ];
        rows += unparse([row]) + "\n";
      });
      controller.enqueue(encoder.encode(rows));
    },
  });
};

const createAndRetrievePortfolioSummary = async (
  portfolioId: string,
  secret: SecretSalt,
  stamps: string[]
): Promise<ArrayBuffer> => {
  const action = supabaseApi.endpoints.getAllPortfolioAssetsEncrypted.initiate(portfolioId);
  const { data: encryptedAssets } = await store.dispatch(action);

  if (!encryptedAssets) {
    throw new Error("Failed to get assets for portfolio.");
  }

  // Decrypt all encrypted UUIDs.
  const promises = encryptedAssets.map(async ({ data, encryptedId }) => {
    const decryptedData = data ? JSON.parse(await decryptData(secret, hexToArrayBuffer(data))) : null;
    const decryptedId = await decryptData(secret, hexToArrayBuffer(encryptedId));

    return {
      data: decryptedData,
      uuid: decryptedId,
    };
  });
  const assets = await Promise.all(promises);

  const body: PortfolioExportSummaryRequest = {
    assets: assets.map(({ uuid, data }) => {
      const { marketValue, mortgageRate, replacementCost } = getDefaultInputValues(data?.archetype);
      return {
        uuid,
        mv: data.financial?.market_value ?? marketValue,
        rc: data.financial?.replacement_cost ?? replacementCost,
        mr: data.financial?.interest_rate ?? mortgageRate,
      };
    }),
  };

  const currencyCode = getCurrencyCode();
  if (currencyCode) {
    body.settings = {
      currency_code: currencyCode,
    };
  }

  if (stamps.length) {
    body.stamps = stamps;
  }

  return new Promise((resolve, reject) => {
    fetch(`${facadesUrl}portfolio/request`, {
      method: "POST",
      headers: {
        Authorization: `Basic ${process.env.NEXT_PUBLIC_FACADES_ABCDE_API_KEY}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(async (res) => {
        if (!res.body) {
          reject({
            type: "failure",
            errors: ["No response from server."],
          });
          return;
        }

        const decoder = new TextDecoder();
        const reader = res.body.getReader();

        let summaryId = "";

        while (!summaryId) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          const data = await reader.read();
          const value = decoder.decode(data.value);

          const lines = value.trim().split("\n");

          if (!lines.length) {
            continue;
          }

          // Determine line based on whether the final line is a partial or not.
          // NOTE: This is because we're dealing with streaming lines.
          const line = lines[lines.length - 1].endsWith("}") ? lines[lines.length - 1] : lines[lines.length - 2];

          const { status, report_id } = JSON.parse(line);
          if (status === "completed") {
            summaryId = report_id;
          }
        }

        await store.dispatch(
          supabaseApi.endpoints.updatePortfolioReport.initiate({
            id: portfolioId,
            summaryId,
          })
        );

        downloadPortfolioSummary({ summaryId })
          .then(async (data) => {
            resolve(await data.arrayBuffer());
          })
          .catch((err) => {
            reject({
              type: "failure",
              errors: [err],
            });
          });
      })
      .catch((err) => {
        reject({
          type: "failure",
          errors: [err],
        });
      });
  });
};

/**
 * Retrieve a summary of a Portfolio Export summary.
 * @param portfolioId - ID of the Portfolio Export portfolio we want a summary of.
 * @param summaryId - ID of previously generated summary
 * @param credentials - Current user's credentials for decrypting Supabase data.
 */
export const exportPortfolioSummary = async (
  portfolioId: string,
  summaryId: string,
  secret: SecretSalt,
  stamps: string[]
): Promise<ArrayBuffer> => {
  if (summaryId) {
    return new Promise((resolve, reject) => {
      downloadPortfolioSummary({ summaryId })
        .then(async (res) => {
          if (!res.ok) {
            throw "Portfolio Summary not found";
          }
          resolve(await res.arrayBuffer());
        })
        .catch(async (err) => {
          console.log("Error:", err);
          return await createAndRetrievePortfolioSummary(portfolioId, secret, stamps)
            .then((res) => resolve(res))
            .catch((err) => {
              reject({
                type: "failure",
                errors: [err],
              });
            });
        });
    });
  }

  return await createAndRetrievePortfolioSummary(portfolioId, secret, stamps);
};

export interface CompletedAssets {
  successes: string[];
  errors: PortfolioExportResultError[];
}

export const streamProgress = (ids: string[], signal: AbortSignal | null = null): Promise<any> => {
  return fetch(`${facadesUrl}${getModeEndpoint()}/progress`, {
    method: "POST",
    headers: {
      Authorization: `Basic ${process.env.NEXT_PUBLIC_FACADES_ABCDE_API_KEY}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(ids),
    signal,
  }).then((data) => ({
    type: "success",
    stream: data.body,
  }));
};

export const streamResultsErrorsOnly = (ids: string[]): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(`${facadesUrl}${getModeEndpoint()}/results?mode=only_api_errors`, {
      method: "POST",
      headers: {
        Authorization: `Basic ${process.env.NEXT_PUBLIC_FACADES_ABCDE_API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ids),
    })
      .then((data) => {
        resolve({
          type: "success",
          stream: data.body,
        });
      })
      .catch((err) => {
        reject({
          type: "failure",
          errors: [err],
        });
      });
  });
};

const getModeEndpoint = () => {
  switch (getRiskBandsMode()) {
    case "abc":
      throw new Error("Portfolio Export not implemented for ABC.");

    case "halo":
      return "project_halo";

    // NOTE: We access residential, but Façades allows getting commercial via this too.
    case "simple":
      return "structural/simple/residential";
  }
};
