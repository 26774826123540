import { Terms } from "../../types/terms";

const terms: Terms = {
  adapted: "Adapted",
  address: "Address",
  archetype: "Asset Type (Archetype)",
  asset: "Asset",
  assetLevel: "Asset-Level",
  baseline: "Baseline",
  buildYear: "Build Year",
  chart: "Chart",
  cost: "Cost",
  count: "Count",
  documents: {
    privacyPolicy: "Privacy Policy",
    termsOfService: "Terms of Service",
  },
  element: "Element",
  hazard: "Hazard",
  hazards: {
    all: "All Hazards",
    coastalInundation: "Coastal Inundation",
    cycloneWind: "Cyclone Wind",
    extremeHeat: "Extreme Heat",
    extremeWind: "Extreme Wind",
    forestFire: "Forest Fire",
    freezeThaw: "Freeze-Thaw",
    riverineFlood: "Riverine Flood",
    soilMovement: "Soil Movement",
    surfacewaterFlooding: "Surface Water Flood",
    tcss: "Tropical Cyclone Storm Surge",
  },
  heightAboveGround: "Height Above Ground",
  id: "ID",
  latitude: "Latitude",
  lgd: "Loss Given Default",
  lgdShort: "LGD",
  list: "List",
  loanToValue: "Loan-to-Value",
  longitude: "Longitude",
  map: "Map",
  material: "Material",
  modules: "Modules",
  mortgageBalance: "Mortgage Balance",
  name: "Name",
  notUsed: "Not Used",
  pd: "Probability of Default",
  pdShort: "PD",
  preview: "Preview",
  rcp: "Representative Concentration Pathway",
  rcpShort: "RCP",
  risk: "Risk",
  riskBands: "Risk Bands",
  scenarios: {
    rcp26: {
      long: "XDI RCP2.6 aligns with NGFS Net Zero 2050",
      medium: "RCP2.6 (Net Zero 2050)",
      short: "RCP2.6",
    },
    rcp45: {
      long: "XDI RCP4.5 aligns with NGFS Delayed Transition",
      medium: "RCP4.5 (Delayed Transition)",
      short: "RCP4.5",
    },
    rcp60: {
      long: "XDI RCP6.0 aligns with NGFS Current Policies (median)",
      medium: "RCP6.0 (Current Policies - Median)",
      short: "RCP6.0",
    },
    rcp85: {
      long: "XDI RCP8.5 aligns with NGFS Current Policies (high range)",
      medium: "RCP8.5 (Current Policies - High Range)",
      short: "RCP8.5",
    },
  },
  stage: "Stage",
  status: {
    error: "Error",
    pending: "Pending",
    processing: "Processing",
    provisioning: "Provisioning",
    success: "Success",
    unknown: "Unknown",
    warning: "Warning",
    uploading: "Uploading",
  },
  subtitle: "Subtitle",
  title: "Title",
  type: "Type",
  units: {
    metres: "metres",
  },
  value: "Value",
};

export default terms;
