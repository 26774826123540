import shapeGridMapperTool from "@/tools/coordinate-generation/shape-grid-mapper";
import gdcr from "@/tools/market-intelligence/gdcr";
import mci from "@/tools/market-intelligence/mci";
import { merge } from "lodash/fp";
import { HubThemePartial } from "../../types";
import xdiThemeBase from "./_base";

const xdiDemoTheme: HubThemePartial = merge(xdiThemeBase, {
  meta: {
    name: "XDI Climate Risk Hub - QA",
    config: {
      featureSet: {
        tools: [...(xdiThemeBase.meta?.config?.featureSet?.tools || []), shapeGridMapperTool, mci, gdcr],
      },
    },
  },
} as HubThemePartial);

export default xdiDemoTheme;
