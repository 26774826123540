import dynamic from "next/dynamic";
import type { ContainerProps as HubMapContainerProps } from "./components/Container";
import { getRealSizeOfMapBounds } from "./utils/bounds";

/**
 * As react-leaflet doesn't support SSR, we have to dynamically import all map
 * components.
 */
export const HubMapContainer = dynamic(() => import("./components/Container").then(data => data.Container), {
  ssr: false
});
export const HubMapControl = dynamic(() => import("./components/MapControl").then(data => data.MapControl), {
  ssr: false
});
export const HubMapGeoJsonDisplay = dynamic(() => import("./maps/GeoJsonDisplay"), {
  ssr: false
});
export const HubMapLocationPicker = dynamic(() => import("./maps/LocationPicker"), {
  ssr: false
});
export const HubMapPointsDisplay = dynamic(() => import("./maps/PointsDisplay"), {
  ssr: false
});
export const HubMapPolygonSelector = dynamic(() => import("./maps/PolygonSelector"), {
  ssr: false
});
export { getRealSizeOfMapBounds };
export type { HubMapContainerProps };