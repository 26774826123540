import { getDocsConfig } from "@/features/branding";
import { CmsPostCategoryId, getCmsPost } from "@/features/cms";
import { CmsPost } from "@/store/services/internal";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, ButtonProps, Drawer, Space } from "antd";
import { ReactNode, useEffect, useState } from "react";
import styles from "./ContextualInfoButton.module.scss";
export interface ContextualInfoButtonProps extends Omit<ButtonProps, "content"> {
  id: string;
  category?: CmsPostCategoryId;
  content?: {
    title: string;
    body: string;
  };
}
const useLoadContent = (id: string, category: CmsPostCategoryId, content?: {
  title: string;
  body: string;
}) => {
  const [post, setPost] = useState<CmsPost | undefined>(undefined);
  useEffect(() => {
    // We don't load the data if tutorials are disabled.
    if (getDocsConfig().contextualElementTutorials.enabled || !content) {
      getCmsPost(category, id).then(setPost);
    }
  }, []);
  if (content) {
    return content;
  }
  if (!post) {
    return {
      title: "",
      body: ""
    };
  }
  return {
    title: post.title,
    body: post.body && post.body !== "null" ? post.body : "<i>Unfortunately, this page has not yet been written.</i>",
    drawerWidth: 600
  };
};

/**
 * Shows a button in-context which will open a tutorial drawer. Visible only
 * when a tutorial is available.
 */
export const ContextualInfoButton = ({
  id,
  category = "glossary",
  children,
  content: givenContent,
  ...props
}: ContextualInfoButtonProps) => {
  const [open, setOpen] = useState(false);
  const content = useLoadContent(id, category, givenContent);
  const showDrawer = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  // We don't show this button if the information hasn't loaded.
  if (!content.title && !content.body) {
    return <></>;
  }
  return <>
      <Button className={styles.button} onClick={showDrawer} type="text" {...props} data-sentry-element="Button" data-sentry-source-file="ContextualInfoButton.tsx">
        {children || <InfoCircleOutlined />}
      </Button>
      <Drawer title={content.title} open={open} onClose={() => setOpen(false)} placement="right" width={"drawerWidth" in content ? content.drawerWidth : 400} data-sentry-element="Drawer" data-sentry-source-file="ContextualInfoButton.tsx">
        <div className="prose" dangerouslySetInnerHTML={{
        __html: content.body
      }} style={{
        wordWrap: "break-word"
      }} />
      </Drawer>
    </>;
};
export interface ContextualInfoButtonWithTextProps extends ContextualInfoButtonProps {
  children: ReactNode;
}
export const ContextualInfoButtonWithText = ({
  children,
  ...props
}: ContextualInfoButtonWithTextProps) => {
  return <Space data-sentry-element="Space" data-sentry-component="ContextualInfoButtonWithText" data-sentry-source-file="ContextualInfoButton.tsx">
      {children}
      <ContextualInfoButton {...props} data-sentry-element="ContextualInfoButton" data-sentry-source-file="ContextualInfoButton.tsx" />
    </Space>;
};