import { EmbedPageContent } from "@/features/embeds";
import supabase, { EmbedPage } from "@/features/supabase";
import { TrackingConfig } from "@/features/tracking";
import changelogTool from "@/tools/reference/changelog";
import faqsTool from "@/tools/reference/faqs";
import glossaryTool from "@/tools/reference/glossary";
import methodsTool from "@/tools/reference/methods";
import modellingTool from "@/tools/reference/modelling";
import { Tool, ToolsObject } from "@/types/tools";
import { FileTextOutlined } from "@ant-design/icons";
import { AuthSession } from "@supabase/supabase-js";
import { Feature, feature, Polygon } from "@turf/turf";
import { jwtDecode } from "jwt-decode";
import { allThemes } from "./themes";
import defaultTheme from "./themes/_default/index";
import { HubTheme } from "./types";

// Tools which will not be subject to database permissions filtering.
const filterExceptedTools = [glossaryTool.keyPrefix, methodsTool.keyPrefix, modellingTool.keyPrefix, changelogTool.keyPrefix, faqsTool.keyPrefix];

/**
 * Returns the current theme of this instance of Hub.
 * @returns Theme of this instance.
 */
export const getTheme = (): HubTheme => {
  return typeof process !== "undefined" && process.env.NEXT_PUBLIC_THEME_NAME ? allThemes[process.env.NEXT_PUBLIC_THEME_NAME] : defaultTheme;
};

/**
 * Returns the theme configuration of aesthetic choices.
 * @returns Aesthetics configuration data.
 */
export const getAestheticsConfig = () => {
  return getTheme().meta.aesthetics;
};

/**
 * Returns the name of the Hub which is contained within the theme.
 * @returns Custom name of Hub.
 */
export const getAppName = () => {
  return getTheme().meta.name;
};
export const getCommsConfig = () => {
  return getTheme().meta.config.featureSet.comms;
};
export const getDocsConfig = () => {
  return getTheme().meta.config.featureSet.docs;
};
export const getKbConfig = () => {
  return getTheme().meta.config.featureSet.docs.knowledgeBase;
};
export const getLocaleConfig = () => {
  return getTheme().meta.config.locale;
};

/**
 * Returns the specific configuration of the current instance of Hub.
 * @returns Configuration within theme.
 */
export const getConfig = () => {
  return getTheme().meta.config;
};

/**
 * Returns the risk bands mode of the current instance.
 * @returns Current risk bands mode ("abc", "halo", "simple").
 */
export const getRiskBandsMode = () => {
  return getConfig().riskBands.mode;
};

/**
 * Determines if an archetype has an alias, and if so, returns alias instead of value.
 * @param value - string
 * @returns The real value for the archetype.
 */
export function dealiasArchetype<T>(value?: string) {
  return value ? (getArchetypeByValue(value)?.aliasOf || value) as T : undefined;
}
export const getArchetypes = () => {
  return getConfig().riskBands.archetypes;
};

/**
 * Gets the Archetype, by value.
 */
export const getArchetypeByValue = (value: string) => {
  return getArchetypes().find(archetype => archetype.value === value);
};

/**
 * Builds a feature containing the coordinate bounds, if they exist.
 * @returns Coordinate bounds as a feature, or undefined.
 */
export const getCoordinateBounds = (): Feature<Polygon> | undefined => {
  const bounds = getConfig().geocoding.coordinateRestrictions.bounds;
  return bounds ? feature(bounds) : undefined;
};
export const getDefaultInputValues = (archetypeValue = "") => {
  const {
    defaultInputValues
  } = getLocaleConfig();
  const archetype = getArchetypes().find(({
    value
  }) => value === archetypeValue);
  if (archetype && archetype.defaultInputValues) {
    return {
      ...defaultInputValues,
      ...archetype.defaultInputValues
    };
  }
  return defaultInputValues;
};
export const getPages = () => {
  return getConfig().pages;
};

/**
 * Returns an array of available tools.
 * @returns All tools which are available to be used in this Hub instance.
 */
export const getAllTools = (): Tool[] => {
  const allTools = [...getConfig().featureSet.tools];
  const {
    glossary,
    method,
    changeLog,
    faqs,
    modelling
  } = getKbConfig();
  if (glossary.enabled) {
    allTools.push(glossaryTool);
  }
  if (method.enabled || method.publicDocsUrl) {
    allTools.push(methodsTool);
  }
  if (modelling.enabled || modelling.publicDocsUrl) {
    allTools.push(modellingTool);
  }
  if (faqs.enabled) {
    allTools.push(faqsTool);
  }
  if (changeLog.enabled) {
    allTools.push(changelogTool);
  }
  return allTools;
};
export const getAllToolsAsObject = (): ToolsObject => {
  return buildToolsObject(getAllTools());
};

/**
 * Returns the current currency used by the system.
 */
export const getCurrencyCode = () => {
  // TODO: Load this in from some form of settings once able to.
  return getLocaleConfig().defaultCurrency;
};

/**
 * Returns an array of available tools allowed for the current user.
 * @returns All tools which are available to be used for the current user.
 */
export const getTools = async (session: AuthSession | null): Promise<Tool[]> => {
  if (!session) return [];
  const decoded = jwtDecode(session.access_token) as AuthSession["user"];
  const toolsEnabled = decoded.user_metadata?.tools_enabled || [];
  const embedPages: EmbedPage[] = (await supabase.rpc("get_embed_pages")).data || [];
  const filteredTools = getAllTools().filter(tool => {
    return toolsEnabled.includes(tool.keyPrefix) || filterExceptedTools.includes(tool.keyPrefix);
  });

  // Include all required subtools.
  filteredTools.forEach(tool => {
    if (tool.subTools) {
      filteredTools.push(...tool.subTools);
    }
  });
  for (const page of embedPages) {
    filteredTools.push({
      category: "marketIntelligence",
      id: page.slug,
      icon: <FileTextOutlined />,
      keyPrefix: "marketIntelligence." + page.slug,
      render: () => <EmbedPageContent {...page} />,
      overrides: {
        isVisible: true,
        title: page.title,
        subtitle: page.subtitle || "",
        description: page.description || ""
      }
    });
  }

  // Remove all duplicates.
  return Array.from(new Set(filteredTools));
};
export const getToolsAsObject = async (session: AuthSession | null): Promise<ToolsObject> => {
  return buildToolsObject(await getTools(session));
};
export const getTrackingConfig = (): TrackingConfig => {
  return getConfig().featureSet.tracking;
};
export const getSettings = () => {
  return getTheme().meta.config.featureSet.settings;
};
const buildToolsObject = (tools: Tool[]) => {
  return tools.reduce<ToolsObject>((toolsObject, tool) => {
    return {
      ...toolsObject,
      [tool.category]: [...toolsObject[tool.category], tool]
    };
  }, {
    admin: [],
    riskBands: [],
    assetLevelDeepDive: [],
    coordinateGeneration: [],
    create: [],
    aggregate: [],
    dataVisualisation: [],
    marketIntelligence: [],
    structural: [],
    reference: []
  });
};